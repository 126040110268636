var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Deposit Options Details" } },
    [
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:50",
                    expression: "'required|max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { disabled: true, name: "Name" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Message*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  disabled: true,
                  name: "Message",
                  counter: "100",
                  maxlength: "100",
                  rows: "2",
                },
                model: {
                  value: _vm.form.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Message"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Amount*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-2/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal|greaterThanZero",
                    expression: "'required|decimal|greaterThanZero'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  disabled: true,
                  type: "number",
                  min: "0",
                  name: "Amount",
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", _vm._n($$v))
                  },
                  expression: "form.amount",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Amount"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col sm:w-2/5 w-full" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "w-full mt-2",
                  attrs: { disabled: true },
                  model: {
                    value: _vm.form.isPercentage,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isPercentage", $$v)
                    },
                    expression: "form.isPercentage",
                  },
                },
                [_vm._v("Percentage")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Minimum Amount*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-2/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal|greaterThanZero",
                    expression: "'required|decimal|greaterThanZero'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  disabled: true,
                  type: "number",
                  min: "0",
                  name: "Minimum Amount",
                },
                model: {
                  value: _vm.form.minAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "minAmount", _vm._n($$v))
                  },
                  expression: "form.minAmount",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Minimum Amount"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Currency*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  disabled: true,
                  value: _vm.selectedCurrency,
                  name: "Currency",
                  options: _vm.currencyOptions,
                },
                on: { input: _vm.setSelectedCurrency },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Currency"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Minimum Days Before")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-2/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min_value:0",
                    expression: "'required|min_value:0'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  disabled: true,
                  type: "number",
                  min: "0",
                  name: "Minimum Days Before",
                },
                model: {
                  value: _vm.form.minDaysBefore,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "minDaysBefore", _vm._n($$v))
                  },
                  expression: "form.minDaysBefore",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Minimum Days Before"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Installment")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                attrs: { disabled: true },
                model: {
                  value: _vm.form.hasInstallments,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hasInstallments", $$v)
                  },
                  expression: "form.hasInstallments",
                },
              }),
            ],
            1
          ),
        ]),
        _vm.form.hasInstallments
          ? _c("div", [
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Number of Installments")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-4/5 w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        disabled: true,
                        name: "Installments",
                        options: Array.from({ length: 10 }, (_, i) => i + 1),
                      },
                      model: {
                        value: _vm.form.installmentCount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "installmentCount", _vm._n($$v))
                        },
                        expression: "form.installmentCount",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Installments"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Installment Amount")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min_value:0",
                          expression: "'required|min_value:0'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        disabled: true,
                        type: "number",
                        min: "0",
                        name: "Installment Amount",
                      },
                      model: {
                        value: _vm.form.installmentAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "installmentAmount", _vm._n($$v))
                        },
                        expression: "form.installmentAmount",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Installment Amount"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/5 w-full" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "w-full mt-2",
                        attrs: { disabled: true },
                        model: {
                          value: _vm.form.installmentIsPercentage,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "installmentIsPercentage", $$v)
                          },
                          expression: "form.installmentIsPercentage",
                        },
                      },
                      [_vm._v("Percentage")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Repeat")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/5 w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        disabled: true,
                        name: "Repeat",
                        options: Array.from({ length: 31 }, (_, i) => i + 1),
                      },
                      model: {
                        value: _vm.form.installmentRepeat,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "installmentRepeat", _vm._n($$v))
                        },
                        expression: "form.installmentRepeat",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Repeat"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-2/5 w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        disabled: true,
                        value: _vm.selectedRepeatUnit,
                        name: "Repeat Unit",
                        placeholder: "Unit",
                        options: _vm.repeatUnitOptions,
                      },
                      on: { input: _vm.setSelectedRepeatUnit },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Repeat Unit"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              { attrs: { color: "danger" }, on: { click: _vm.onCancel } },
              [_vm._v("Close")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }